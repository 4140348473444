import React from "react";

const Privacy = () => {
  return (
    <>
      <div className=" bg-white">
        <div className=" max-w-[900px] pb-28 md:px-12 px-4 mx-auto">
          <div className=" w-full mx-auto  md:py-[80px] pt-[130px] pb-[50px]  ">
            <h1 className="md:text-5xl text-2xl  font-semibold">
              Privacy Policy
            </h1>
          </div>
          <div className=" bg-white text-black text-md md:text-lg space-y-2">
            <div className="">
              <p className="opacity-70">Effective Date: 14-04-2023</p> <br />
            </div>

            <h2 className="text-xl md:text-2xl opacity-100 font-medium">
              1. Introduction
            </h2>
            <p className="opacity-70">
              This Privacy Policy explains how Atman ("we", "us", or "our")
              collects, uses, discloses, and safeguards your information when
              you use our mobile application and website (the "Service"). By
              accessing or using the Service, you signify your understanding of,
              consent to, and agreement with this Privacy Policy.
            </p>

            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              2. Information Collection
            </h2>
            <p className="opacity-70">
              <span className="">Personal Information:</span> We collect
              personal information that you voluntarily provide to us, including
              but not limited to, name, email address, and contact details.
            </p>

            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              2. Privacy Policy
            </h2>
            <p className="opacity-70">
              Our Privacy Policy, which is incorporated by reference into these
              Terms, explains how we collect, use, and protect your personal
              data. Please read it carefully.
            </p>
            <p className="opacity-70">
              Usage Data: We automatically collect information on how the
              Service is accessed and used ("Usage Data"). This Usage Data may
              include information such as your device's Internet Protocol
              address (IP address), browser type, browser version, the pages of
              our Service you visit, the time and date of your visit, the time
              spent on those pages, and other diagnostic data.
            </p>
            <p className="opacity-70">
              Tracking Technologies and Cookies: We use cookies and similar
              tracking technologies to track activity on our Service and store
              certain information.
            </p>

            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              3. Use of Data
            </h2>
            <p className="opacity-70">
              The data we collect may be used for various purposes including,
              but not limited to, providing and maintaining our Service,
              notifying you about changes to our Service, allowing you to
              participate in interactive features of our Service, providing
              customer care and support, monitoring the usage of our Service,
              detecting, preventing, and addressing technical issues, and
              providing you with news, special offers, and general information
              about other goods, services, and events.
            </p>

            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              4. Data Sharing and Disclosure
            </h2>
            <p className="opacity-70">
              We may share your data with third-party service providers who
              perform services on our behalf, including data analysis, email
              delivery, hosting services, customer service, and marketing
              assistance. s.
            </p>
            <p className="opacity-70">
              We may disclose your data where required to do so by law or in
              response to valid requests by public authorities (e.g., a court or
              a government agency).
            </p>
            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              5. Data Security
            </h2>
            <p className="opacity-70">
              We strive to use commercially acceptable means to protect your
              Personal Information, but remember that no method of transmission
              over the Internet or method of electronic storage is 100% secure.
            </p>

            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              6. Data Retention
            </h2>
            <p className="opacity-70">
              We will retain your Personal Information only for as long as is
              necessary for the purposes set out in this Privacy Policy.
            </p>

            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              7. International Data Transfers
            </h2>
            <p className="opacity-70">
              Your information, including Personal Data, may be transferred to —
              and maintained on — computers located outside of your state,
              province, country, or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
            </p>

            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              8. Your Data Protection Rights
            </h2>
            <p className="opacity-70">
              You have certain data protection rights, including the right to
              access, update, or delete the information we have on you, the
              right of rectification, the right to object, the right of
              restriction, and the right to data portability.
            </p>
            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              9. Links to Other Sites
            </h2>
            <p className="opacity-70">
              Our Service may contain links to other sites that are not operated
              by us. If you click on a third-party link, you will be directed to
              that third party's site. We strongly advise you to review the
              Privacy Policy of every site you visit.
            </p>

            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              10. Children's Privacy
            </h2>
            <p className="opacity-70">
              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13.
            </p>

            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              11. Changes to This Privacy Policy
            </h2>
            <p className="opacity-70">
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </p>
            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              12. Contact Us
            </h2>
            <p className="opacity-70">
              If you have any questions or suggestions about our Privacy Policy,
              do not hesitate to contact us at
              <span className="text-blue-600"> hello@atman.bio</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
