import React from "react";

const Credits = () => {
  return (
    <>
      <div className="max-w-[1440px] block md:hidden text-white opacity-40 px-6 md:px-12 py-4 justify-between  mx-auto flex">
        <p className="text-sm "> ©atman</p>
        <div className="flex gap-3">
          <p className="text-sm underline"> privacy</p>
          <p className="text-sm underline"> terms</p>
        </div>
      </div>
    </>
  );
};

export default Credits;
