import React from "react";
import "../App.css";

const UnveilNewWorlds = () => {
  return (
    <div className="max-w-[1440px] mx-auto">
      {/* Use 'items-center' for flex and 'place-items-center' for grid layouts */}
      <div className="grid grid-cols-1 px-6 py-12 md:py-[100px] mb-10 lg:grid-cols-2 md:gap-20 items-center place-items-center sm:flex sm:justify-evenly sm:mt-8">
        <img
          src="/Assets/images/naruto2.gif"
          className="h-[200px] bg-cover object-cover  mb-8 md:mb-0 sm:h-[265px] sm:w-[300px] lg:h-[400px] lg:w-[500px] xl:h-[500px] xl:w-[600px] 2xl:h-[600px] 2xl:w-[600px]"
          alt=""
        />

        <div className="flex flex-col justify-center">
          <h1 className="text-3xl font-bold text-primary sm:text-3xl lg:text-5xl xl:text-6xl">
            Unveil New Worlds
          </h1>
          <p className="text-primary text-lg font-light pt-3 sm:text-[14px] lg:mt-2 lg:text-lg xl:mt-8">
            Ever fancied a casual chat with a rockstar coder or a master chef?
            Dive in! It's time to break barriers and chat with the pros, all
            while keeping the vibe breezy and fun.
          </p>
          <p className="text-primary text-lg font-light pt-3 sm:text-[14px] lg:mt-2 lg:text-lg">
            Think of it as having a cup of ramen with Naruto or sharing a laugh
            with Goku, just a laid-back space to connect and learn.
          </p>
          <p className="text-primary text-[16px] font-light pt-3 hidden sm:block lg:mt-2 lg:text-lg xl:visible 2xl:visible">
            Experience the thrill of real-time video conversations with your
            favorite expert. Ask them questions, share stories, and get to know
            them on a personal level. It's like having a private one-on-one chat
            with an expert.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UnveilNewWorlds;
