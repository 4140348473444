import React from "react";
import IconCard from "./IconCard";

const Steps = () => {
  return (
    <div
      className=" max-w-[1440px] mx-auto py-[100px] z-20 bg-dark px-6 pt-20 lg:px-12
    "
    >
      <h3 className="text-primary do-better text-center text-3xl mb-12 sm:text-4xl xl:text-6xl font-bold leading-relaxed">
        Effortless Influencer Connections in Three Simple Steps
      </h3>

      <div
        className=" max-w-[1440px] mx-auto grid grid-cols-1 items-center sm:grid-cols-1  md:grid-cols-3 md:flex gap-2 lg:grid-cols-5 
      "
      >
        <div className="  py-10 mx-auto sm:w-[300px] sm:mx-auto  rounded-[20px] sm:py-6  lg:px-0 md:w-auto sm:border-visible md:border-hidden lg:w-auto lg:ml-0  ">
          <IconCard
            imageUrl="/Assets/icon1.png"
            heading="Discover & Connect"
            title="Craft a compelling prompt that encourages users to discover new connections through the platform."
          />
        </div>
        <div className=" flex mx-auto  mt-10 mb-10  md:invisible  lg:visible lg:w-[200px] lg:mx-auto text-center  ">
          <img src="/Assets/img9.png" alt="" />
        </div>
        <div className=" sm:w-[300px]   py-10 mx-auto  rounded-[20px] sm:only:py-6  md:w-auto sm:border-visible md:border-hidden lg:w-auto ">
          <IconCard
            imageUrl="/Assets/icon2.png"
            heading="Set a Date"
            title="Facilitate easy scheduling of collaborations with influencers to streamline your networking process."
          />
        </div>
        <div className="flex mx-auto   mt-10 mb-10 md:invisible lg:visible lg:w-[200px] ">
          <img src="/Assets/img9.png " alt="" />
        </div>
        <div className="  py-10 mx-auto sm:w-[300px] sm:mx-auto lg:mx-0  rounded-[20px] sm:py-6  lg:px-0 md:w-auto  sm:border-visible  md:border-hidden lg:w-auto">
          <IconCard
            imageUrl="Assets/icon3.png"
            heading=" Start the Conversation"
            title="Initiate engaging conversations and partnerships with influencers, fostering valuable connections."
          />
        </div>
      </div>
    </div>
  );
};

export default Steps;
