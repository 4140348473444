import React, { useState } from "react";
import Button from "./Button";
import "../App.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import users from "../data/users";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Banner = () => {
  return (
    <>
      <div className=" max-w-[1440px] px-5 md:overflow-hidden md:grid md:grid-cols-2 md:gap-10 lg:gap-20 lg:px-12 py-12 lg:py-12 md:px-12   md:h-screen mx-auto">
        <div className="md:my-auto md:justify:between">
          <div className="  lg:py-0 mt-12 lg:-mt-12">
            <h1 className=" lg:text-xl do- text-white  lg:mb-5 md:pt-4">
              We thrive to do better
            </h1>
            <h1 className=" do-better text-5xl py-5 text- lg:text-7xl font-semibold font-Manrope text-white lg:mb-8 leading-tight lg:leading-snug">
              Dive into a World of Exiciting Connections!
            </h1>
            <div className="grid gap-4 md:grid-cols-2 y-6">
              <Button type="primary" onClick={() => {}}>
                Find An Expert
              </Button>
              <Button type="secondary" onClick={() => {}}>
                Learn More
              </Button>
            </div>
          </div>
          <div className="hidden md:block">
            <h1 className=" text-6xl text-white font-semibold mt-12">10K+</h1>
            <h1 className="text-white text-2xl mt-3 mb-30">
              EXPERTS TO CONNECT WITH
            </h1>
          </div>
        </div>

        <div className="  md:overflow-hidden md:grid md:grid-cols-1 lg:grid lg:grid-cols-2 lg:gap-8 md:relative">
          <div className=" md:hidden scroll-horizontal mt-8">
            <Swiper
              modules={[Autoplay]}
              spaceBetween={12}
              slidesPerView={1.5}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 2000, // Each slide stays for 5 seconds
                disableOnInteraction: false,
              }}
              speed={5000} // Transition between slides also takes 5 seconds
              className="mySwiper"
              // Other settings...
            >
              {users.map((user, index) => (
                <SwiperSlide key={index}>
                  <img
                    className="object-cover w-full h-[200px]"
                    src={user.img}
                    alt={`Logo ${user.id}`}
                  />
                  <p className="text-md w-4/5 text-center pt-2 text-white">
                    {user.title}
                  </p>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="hidden  justify-self-end  md:block animate-scroll">
            {users.concat(users).map((src, index) => (
              <div>
                <img
                  key={index}
                  src={src.img}
                  className="md:w-[3000px] lg:w-[400px] pt-6"
                  alt={src.name}
                />
                <h3 className="text-primary text-center py-4  text-xl pb-6 sm:text-md ">
                  {src.title}{" "}
                </h3>
              </div>
            ))}
          </div>

          <div className="animate-scroll-reverse hidden lg:block overflow-hidden relative">
            {users.concat(users).map((src, index) => (
              <div key={index}>
                <img
                  key={index}
                  src={src.img}
                  className="md:w-[3000px] lg:w-[400px] pt-6"
                  alt={src.name}
                />
                <h3 className="text-primary text-center py-4 text-xl pb-6  ">
                  {src.title}{" "}
                </h3>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-white h-16 flex items-center justify-center">
        <div className="scroll-text flex relative">
          <p className="md:text-xl font-medium md:font-semibold py-2">
            <span className="inline-img pr-2">
              <img src="/Assets/images/vector.png " alt="Vector" />
            </span>
            Develop a digital hub connecting enthusiasts with a vast network of
            100,000+ experts, fostering collaborative learning and knowledge
            exchange at an unprecedented scale. &nbsp;&nbsp;&nbsp;&nbsp;
            <span className="inline-img pr-2">
              <img src="/Assets/images/vector.png" alt="Vector " />
            </span>
            Develop a digital hub connecting enthusiasts with a vast network of
            100,000+ experts, fostering collaborative learning and knowledge
            exchange at an unprecedented scale.
          </p>
        </div>
      </div>
    </>
  );
};
export default Banner;
