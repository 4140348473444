import React from "react";

const CTA = () => {
  return (
    <>
      <div className="bg-white font-Manrope font-bold md:flex flex md:flex-row flex-col-reverse gap-6 pb-12 md:pb-0">
        <div className="max-w-[740px]  py-auto my-auto text- px-6 md:px-12 mx-auto space-y-6 ">
          <p className="text-3xl md:text-4xl lg:text-6xl  leading-tight">
            Join the Conversation
          </p>
          <p className="font-medium text-lg md:text-lg  text-gray-800">
            Are you ready to leap into a world rich with friendship and shared
            stories? Click 'Join the Conversation' and begin an exciting chapter
            in your storybook of connections!
          </p>
          <div className="flex flex-row md:flex-row items- justify- space-x-2  md:space-y-0 md:space-x-6 md:pt-4">
            <img
              src="/Assets/images/google-play.png"
              alt="atman play store link"
              className="h-11 md:h-16 lg:h-18"
            />
            <img
              src="/Assets/images/app-store.png"
              alt="atman app store link"
              className="h-11 md:h-16 lg:h-18"
            />
          </div>
        </div>
        <img
          src="/Assets/images/mockup/mockup.png"
          className="md:h-[500px] lg:h-[700px] h-[400px] object-cover "
          alt=""
        />
      </div>
    </>
  );
};

export default CTA;
