import * as React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import WaitList from "./pages/WaitList";
import Footer from "./Footer";
import MobileNav from "./layout/MobileNav";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import MobileFooter from "./Components/MobileFooter";

const App = () => {
  return (
    <>
      <MobileNav />
      <Footer />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/waitlist" element={<WaitList />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <MobileFooter />
    </>
  );
};
export default App;
