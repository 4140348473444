import React from "react";
import { AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";
import { Link } from "react-router-dom";

const MobileFooter = () => {
  return (
    <>
      <div className=" px-4 my-auto space-y-12 py-12 md:hidden block">
        <div className="space-x-12">
          <Link to="/waitlist">
            <p className=" text-gray-500 hover:text-white text-lg  transition-colors duration-500 ease-in-out cursor-pointer">
              Waitlist
            </p>
          </Link>
          <Link to="/waitlist">
            <p className=" text-gray-500 hover:text-white text-lg  transition-colors duration-500 ease-in-out cursor-pointer">
              Join Us
            </p>
          </Link>
          <Link to="/privacy">
            <p className="text-lg text-gray-500 hover:text-white  transition-colors duration-500 ease-in-out cursor-pointer">
              Privacy Policy
            </p>
          </Link>
          <Link to="/terms">
            <p className="text-lg  text-gray-500 hover:text-white  pr-6 transition-colors duration-500 ease-in-out cursor-pointer">
              Terms of service
            </p>
          </Link>
        </div>

        <div className="flex space-x-4">
          <a href="https://instagram.com/atmanvibe" target="_">
            <AiFillInstagram
              color=""
              size={24}
              className="text-gray-500  hover:text-white "
            />
          </a>
          <a href="https://discord.gg/9ZPXbFRCQ3" target="_">
            <FaDiscord size={22} className="text-gray-500  hover:text-white " />
          </a>
          <a href="https://youtube.com/@atmanhq" target="_">
            <AiFillYoutube
              size={24}
              className="text-gray-500  hover:text-white "
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default MobileFooter;
// in01-388-r231121001 - repair order number
// rise and fall
