import React from "react";
import { AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className=" bg-dark fixed bottom-0 left-0 right-0 shadow-t invisible md:visible z-50 ">
      <nav className=" flex justify-between max-w-[1440px] mx-auto  h-16  px-12  ">
        <Link to="/">
          <img
            alt=""
            src="/Assets/logo.png"
            className="h-7 my-auto  sm:h-6 mt-7"
          />
        </Link>
        <ul className="flex items-center py-5 space-x-10 text-primary sm:px-16">
          {/* <li className="text-lg md:text-md text-gray-500 hover:text-white transition-opacity cursor-pointer font-extralight delay-500">
            Find Experts
          </li>
          <li className="text-lg md:text-md text-gray-500 hover:text-white transition-opacity duration-600 delay- cursor-pointer font-extralight">
            Our Team
          </li> */}

          <Link to="/waitlist">
            <li className="text-lg md:text-md text-gray-500 hover:text-white transition-colors duration-500 ease-in-out cursor-pointer font-extralight">
              Waitlist
            </li>
          </Link>
        </ul>

        <div className="flex my-auto space-x-4">
          <Link to="/privacy">
            <p className="text-sm text-gray-500 hover:text-white underline transition-colors duration-500 ease-in-out cursor-pointer">
              privacy
            </p>
          </Link>
          <Link to="/terms">
            <p className="text-sm text-gray-500 hover:text-white underline pr-6 transition-colors duration-500 ease-in-out cursor-pointer">
              terms
            </p>
          </Link>
          <a href="https://instagram.com/atmanvibe" target="_">
            <AiFillInstagram
              color=""
              size={22}
              className="text-gray-500  hover:text-white "
            />
          </a>
          <a href="https://discord.gg/9ZPXbFRCQ3" target="_">
            <FaDiscord size={22} className="text-gray-500  hover:text-white " />
          </a>
          <a href="https://youtube.com/@atmanhq" target="_">
            <AiFillYoutube
              size={22}
              className="text-gray-500  hover:text-white "
            />
          </a>
        </div>
      </nav>
    </div>
  );
};

export default Footer;
