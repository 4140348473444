import { User } from "../types/UserTypes";

const users: User[] = [
  {
    id: 1,
    img: "https://images.pexels.com/photos/3039036/pexels-photo-3039036.jpeg?auto=compress&cs=tinysrgb&w=1600",
    title: "1hey man, how is it going",
    name: "",
  },
  {
    id: 2,
    img: "https://images.pexels.com/photos/3039036/pexels-photo-3039036.jpeg?auto=compress&cs=tinysrgb&w=1600",
    title: "1hey man, how is it going",
    name: "",
  },
  {
    id: 3,
    img: "https://images.pexels.com/photos/3039036/pexels-photo-3039036.jpeg?auto=compress&cs=tinysrgb&w=1600",
    title: "1hey man, how is it going",
    name: "",
  },
  {
    id: 4,
    img: "https://images.pexels.com/photos/3039036/pexels-photo-3039036.jpeg?auto=compress&cs=tinysrgb&w=1600",
    title: "1hey man, how is it going",
    name: "",
  },
  {
    id: 5,
    img: "https://images.pexels.com/photos/3039036/pexels-photo-3039036.jpeg?auto=compress&cs=tinysrgb&w=1600",
    title: "1hey man, how is it going",
    name: "",
  },
  {
    id: 6,
    img: "https://images.pexels.com/photos/3039036/pexels-photo-3039036.jpeg?auto=compress&cs=tinysrgb&w=1600",
    title: "1hey man, how is it going",
    name: "",
  },
];
export default users;
