import React, { ReactNode } from "react";

interface ButtonProps {
  type: "primary" | "secondary";
  onClick: () => void;
  children: ReactNode;
}

const Button: React.FC<ButtonProps> = ({ type, onClick, children }) => {
  const buttonClasses = `button ${
    type === "primary"
      ? "bg-white text-xl md:py-4 py-3 font-light  " // TODO- compare this with previous code
      : "text-white border-zinc-800 border-2 text-xl md:py-4 py-3"
  }`;

  return (
    <button className={buttonClasses} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
