import { BsChevronDown } from "react-icons/bs";
import React, { useState } from "react";
import accordian from "../data/accordian";

const AccordionItem = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(0);
  const [isHovered, setIsHovered] = useState(false);
  const [color, setColor] = useState(""); // Initialize color state
  const [selectedImage, setSelectedImage] = useState(""); // Initialize selectedImage state

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const toggleItem = (index: number, item: (typeof accordian)[number]) => {
    if (index === openIndex) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
      setColor(item.color); // Set the color state based on the clicked item
      setSelectedImage(item.imageUrl); // Set the selectedImage state based on the clicked item
    }
  };

  return (
    <div className="bg-white">
      <div className="lg:grid grid-cols-1 md:px-12 md:py-[100px]   lg:grid-cols-2 gap-20 items-center place-items-center sm:flex sm:justify-evenly sm:mt-8 justify-b max-w-[1440px] mx-auto  ">
        <div className="w-full  ">
          {accordian.map((item, id) => (
            <div
              key={id}
              className={`accordion-item hover:bg-violet-50  ${
                openIndex === id ? "bg-violet-50" : ""
              }`}
            >
              <div>
                <div
                  onClick={() => toggleItem(id, item)}
                  className="accordion-title font-bold flex justify-between py-6 px-7 text-xl  md:text-2xl items-center  sm:font-semibold"
                >
                  <span
                    className={`text-black-400 ${
                      openIndex === id
                        ? "text-violet-600 font-bold text-xl md:text-2xl xl:bold 2xl:font-semibold lg:font-bold md:font-bold  "
                        : ""
                    }`}
                  >
                    <span className="text-violet-600 text-[14px] font-bold mr-2 ">
                      {item.id}
                    </span>
                    {item.title}
                  </span>
                  <span
                    className="
                
                
                "
                  >
                    <BsChevronDown className=" " />
                  </span>
                </div>
              </div>

              {openIndex === id && (
                <div className="accordion-content text-black text-lg pb-8 px-12 sm:text-lg xl:text-[20px]">
                  {item.desc}
                </div>
              )}

              {/* {openIndex === id && (
                <div
                  className={`${color} bg-orange-300  flex  mx-auto mb-12 items-center rounded-[40px]  md:visible lg:hidden xl:invisible `}
                >
                  {selectedImage && (
                    <img
                      src={selectedImage}
                      alt="Selected "
                      className="h-[180px] w-[180px] sm:h-[300px] sm:w-[280px] mx-auto object-cover "
                    />
                  )}
                </div>
              )} */}

              <hr />
            </div>
          ))}
        </div>
        <div
          className={` bg-orange- h-[500px]  flex   mx-auto  items-center rounded-[60px] hidden md:block  w-full`}
        >
          {/* {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected "
              className="h-[250px] w-[200px] xl:h-[350px] xl:w-[300px] rounded-3xl mx-auto lg:visible "
            />
        )} */}

          <img
            src="/Assets/images/convers.svg"
            alt="Selected "
            className="  mx-auto lg:visible "
          />
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
