import React from "react";
import "../App.css";
const ComingSoon = () => {
  return (
    <div className="flex flex-col justify-center items-center mx-auto md:w-4/5 h-screen text-white space-y-3 md:space-y-3">
      <div>
        <img alt="" src="/Assets/logo.png" className="h-4 sm:h-4 md:h-6" />
      </div>
      <div className="md:w-3/5 text-center ">
        <p className="text-4xl md:text-6xl leading-snug md:leading-normal font-bold">
          Get Ready to Connect & Elevate!{" "}
        </p>
        {/* <p className="text-6xl">Unlock a World of Possibilities</p> */}
        {/* <p>
          We're crafting an extraordinary space where experts, influencers, and
          brilliant minds unite. Your journey to inspiration, networking, and
          growth is about to get a major upgrade!
        </p> */}
      </div>
      <p className="text-2xl md:text-3xl md:w-4/5  text-center  mx-auto text-[#f05e5d] m">
        Stay tuned...
      </p>
      {/* <div className="flex flex-col items-center justify-center pt-6">
        <div className="flex gap-4 mb-4">
          <input
            type="email"
            placeholder="gavin@hooli.com"
            className="bg-gradient-to-r text-regular lighter-placeholder from-gray-800 to-black p-2 pl-4 rounded-l-lg shadow-lg transition-all duration-300 placeholder-gray-500 focus:outline-none focus:ring-0"
          />

          <button className="text-white p-2 pr-6 pl-6 rounded-r-lg font-bold hover:ring-blue-600 hover:shadow-lg transition-all duration-300">
            Subscribe
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default ComingSoon;
