import React, { useState } from "react";
import { AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";
import { Link } from "react-router-dom";

const MobileNav = () => {
  const [isNavOpen, setNavOpen] = useState(false);
  const [isIconRotated, setIconRotated] = useState(false);

  const toggleNavbar = () => {
    setNavOpen(!isNavOpen);
    setIconRotated(!isIconRotated);
  };

  return (
    <>
      <div className="fixed top-0 left-0 right-0 bg-black sm:hidden  border-zinc-600 py-6 z-20">
        <div className="">
          <img
            src="/Assets/images/icons/add.svg"
            color="white"
            className={`float-right w-5 mr-6 text-3xl color- font-thin transform ${
              isIconRotated ? "rotate-45" : "rotate-0"
            } transition-transform duration-300 ease-in-out`}
            onClick={toggleNavbar}
            alt="atman logo"
          />
        </div>
        <div className="w-fit">
          <Link className="w-fit" to="/">
            <img
              src="/Assets/images/logo.png"
              className="h-6 ml-5 w-fit"
              alt="atman logo"
            />
          </Link>
        </div>
      </div>
      {isNavOpen && (
        <div className="fixed top-0 right-0 left-0 h-screen bg-black z-10 flex flex-col justify-between pb-20">
          <nav className="pr-8 pt-8">
            <ul className="text-end space-y-">
              <Link to="/waitlist" onClick={toggleNavbar}>
                <li className="text-gray-500 pb-4 mt-28 text-xl w-fit ml-auto transition-colors duration-500 ease-in-out cursor-pointer">
                  waitlist
                </li>
              </Link>
              <Link to="/waitlist" onClick={toggleNavbar}>
                <li className="text-gray-500 pb-4 text-xl transition-colors duration-500 ease-in-out cursor-pointer">
                  join us
                </li>
              </Link>
            </ul>
          </nav>
          <div className="space-y-3">
            <div className="flex my-auto ml-auto space-x-4 justify-end pr-8">
              <AiFillInstagram
                color=""
                size={18}
                className="text-gray-500  hover:text-white "
              />
              <FaDiscord
                size={18}
                className="text-gray-500  hover:text-white "
              />
              <AiFillYoutube
                size={18}
                className="text-gray-500  hover:text-white "
              />
            </div>
            <div className="flex justify-end">
              <Link to="/terms" onClick={toggleNavbar}>
                <p className="text-gray-500 text-xs text-end pr-1 transition-colors duration-500 ease-in-out cursor-pointer">
                  terms of use |
                </p>
              </Link>
              <Link to="/privacy" onClick={toggleNavbar}>
                <p className="text-gray-500 text-xs text-end pr-8 transition-colors duration-500 ease-in-out cursor-pointer">
                  privacy policy |
                </p>
              </Link>
            </div>

            <p className="text-gray-500 text-xs text-end pr-8 pb-8">
              @Atman Inc. 2023
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileNav;
