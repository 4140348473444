import axios from "axios";
import React, { useState } from "react";
import { Helmet } from "react-helmet";

interface FormData {
  fullName: string;
  email: string;
  accountHandle: string;
  country: string;
}

const WaitList: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    fullName: "",
    email: "",
    accountHandle: "",
    country: "",
  });

  interface FormErrors {
    fullName?: string;
    email?: string;
  }

  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validateForm = () => {
    const errors: FormErrors = {};
    if (!formData.fullName.trim()) {
      errors.fullName = "Full name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    }
    return errors;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    setIsLoading(true);
    try {
      await axios.post("https://formspree.io/f/xaygkwyw", formData);
      setIsSubmitted(true);
    } catch (error) {
      console.error("Form submission error:", error);
      // Handle the error accordingly
    } finally {
      setIsLoading(false);
    }
  };

  if (isSubmitted) {
    return (
      <div className="text-white bg-black text-5xl h-screen text-center pt-8 ">
        Thank you
      </div>
    );
  }
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-dark">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Join the Waitlist</title>
      </Helmet>
      <div className="font-Manrope text-center space-y-4 md:mb-8 mb-6">
        {/* <h2 className="text-white mx-auto text-3xl font-bold ">
          Atman Universe
        </h2> */}
        {/* <div className="flex justify-center mt-8 items-center">
          <img src="/Assets/images/logo.png" className="h-7" alt="atman logo" />
        </div> */}
        <p className="text-white text-lg mt-8">
          Join the waitlist for priority access.
        </p>
      </div>
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-xl p-8 rounded-lg bg-dark  items-center "
      >
        <div className="md:mb-14 mb-10">
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="Full name *"
            className={`w-full bg-transparent border-b-[1px] ${
              formErrors.fullName
                ? "border-red-500 "
                : "border-white border-opacity-20"
            } text-white placeholder-white placeholder-opacity-50 focus:border-opacity-40 focus:placeholder-font-semibold outline-none pb-1 font-Manrope pl-2 text-md placeholder:text-[16px] text-lg rounded-none`}
          />
          {formErrors.fullName && (
            <p className="text-red-500 text-xs mt-1">{formErrors.fullName}</p>
          )}
        </div>
        <div className="md:mb-14 mb-10">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email *"
            className={`w-full bg-transparent border-b-[1px] ${
              formErrors.email
                ? "border-red-500"
                : "border-white border-opacity-20"
            } text-white placeholder-white placeholder-opacity-50 focus:border-opacity-40 focus:placeholder-opacity-70 focus:placeholder-font-semibold outline-none pb-2 font-Manrope pl-2 text-md placeholder:text-[16px] text-lg rounded-none`}
          />
          {formErrors.email && (
            <p className="text-red-500 text-xs mt-1">{formErrors.email}</p>
          )}
        </div>
        <div className="md:mb-14 mb-10">
          <input
            type="text"
            name="accountHandle"
            value={formData.accountHandle}
            onChange={handleChange}
            placeholder="Profile Url"
            className="w-full bg-transparent border-b-[1px] border-white border-opacity-20 text-white placeholder-white placeholder-opacity-50 focus:border-opacity-40 focus:placeholder-opacity-70 focus:placeholder-font-semibold outline-none pb-2 font-Manrope pl-2 text-md placeholder:text-[16px] text-lg rounded-none"
          />
        </div>
        <div className="md:mb-14 mb-10">
          <select
            name="country"
            value={formData.country}
            onChange={handleChange}
            className="w-full bg-transparent border-b-[1px] border-white border-opacity-20 text-white placeholder-white placeholder-opacity-50 focus:border-opacity-40 focus:placeholder-opacity-70 focus:placeholder-font-semibold outline-none pb-2 font-Manrope pl-2 text-md placeholder:text-[16px] text-lg"
          >
            <option value="" disabled>
              Profession
            </option>
            <option value="artist">Artist</option>
            <option value="doctor">Doctor</option>
            <option value="engineer">Engineer</option>
            <option value="entrepreneur">Entrepreneur</option>
            <option value="influencer">Influencer</option>
            <option value="lawyer">Lawyer</option>
            <option value="psy">Psychologist</option>
            <option value="figure">Public Figure</option>
            <option value="alien">Alien</option>
          </select>
        </div>
        <p className="text-gray-400 text-xs font-Manrope ">
          Read our <span className="underline"> privacy policy</span> and{" "}
          <span className="underline"> terms </span>
          related to any information you may share with us. Thank you.
        </p>
        <div className="w-full text-center md:mt-12 mt-8">
          <button
            type="submit"
            className="bg-black text-white  font-semibold border-[1px]  font-Manrope border-white px-12 py-3 rounded-full hover:bg-white hover:text-black transition-colors mx-auto"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default WaitList;
