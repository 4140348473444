import React from "react";

interface ImageCardProps {
  imageUrl: string;
  title: string;
  heading: string;
}

const IconCard: React.FC<ImageCardProps> = ({ heading, imageUrl, title }) => {
  return (
    <>
      <div className="image-card flex flex-col justify-center  ">
        <img
          src={imageUrl}
          alt={title}
          className="  h-20 flex justify-center  mx-auto"
        />
        <h1 className="text-primary text-2xl text-center xl:text-[22px] py-4">
          {" "}
          {heading}
        </h1>
        <p className="text-primary text-center font-light text-xl md:text-xl ">
          {title}
        </p>
      </div>
    </>
  );
};

export default IconCard;
