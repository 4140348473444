import React from "react";

const Terms = () => {
  return (
    <>
      <div className=" bg-white">
        <div className=" max-w-[900px] pb-28 md:px-12 px-4 mx-auto">
          <div className=" w-full mx-auto  md:py-[80px] pt-[130px] pb-[50px]  ">
            <h1 className="md:text-5xl text-2xl font-semibold">Terms of Use</h1>
          </div>
          <div className=" bg-white text-black  text-md md:text-lg space-y-2">
            <div className="">
              <p className="opacity-80">Effective Date: 14-04-2023</p> <br />
            </div>
            <div className=" ">
              <p className="opacity-80">
                Welcome to Atman. These terms and conditions outline the rules
                and regulations for the use of Atman's App and Website.
              </p>
              <br />
              <p className="opacity-80">
                By accessing this app we assume you accept these terms and
                conditions. Do not continue to use Atman if you do not agree to
                all of the terms and conditions stated on this page.
              </p>
            </div>
            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              1. Modification of Terms
            </h2>
            <p className="opacity-80">
              We reserve the right to update or modify these Terms at any time
              without prior notice. Your continued use of the Service after any
              such changes constitutes your acceptance of the new Terms.
            </p>

            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              2. Privacy Policy
            </h2>
            <p className="opacity-80">
              Our Privacy Policy, which is incorporated by reference into these
              Terms, explains how we collect, use, and protect your personal
              data. Please read it carefully.
            </p>

            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              3. User Eligibility
            </h2>
            <p className="opacity-80">
              The Service is intended for users who are at least 13 years old.
              If you are under this age, you must have parental consent to use
              the Service.
            </p>

            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              4. User Conduct and Responsibility
            </h2>
            <p className="opacity-80">
              Users agree not to use the App for any unlawful purpose or in a
              way that is prohibited by these terms.
            </p>
            <p className="opacity-80">
              You are responsible for safeguarding the password that you use to
              access the App and for any activities or actions under your
              password.
            </p>
            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              5. Intellectual Property Rights
            </h2>
            <p className="opacity-80">
              All content on the Service, including text, graphics, logos, and
              software, is the property of Atman or its licensors and is
              protected by intellectual property laws. You are granted a
              limited, non-exclusive, non-transferable license to access and use
              the Service for personal, non-commercial purposes.
            </p>

            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              6. User-Generated Content
            </h2>
            <p className="opacity-80">
              You may submit content, including comments and images, to the
              Service. You retain all rights to your content, but you grant us a
              non-exclusive, royalty-free license to use, reproduce, modify, and
              display your content in connection with the Service.
            </p>

            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              7. Limitation of Liability
            </h2>
            <p className="opacity-80">
              Atman will not be liable for any indirect, incidental, special,
              consequential, or punitive damages resulting from your access to
              or use of, or inability to access or use, the App.
            </p>

            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              8. Governing Law and Jurisdiction
            </h2>
            <p className="opacity-80">
              These terms shall be governed and construed in accordance with the
              laws of India, without regard to its conflict of law provisions.
            </p>
            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              9. Dispute Resolution
            </h2>
            <p className="opacity-80">
              Any disputes arising out of or in connection with these terms will
              be resolved through binding arbitration in accordance with the
              rules of India.
            </p>

            <h2 className="text-xl md:text-2xl opacity-100 pt-6 font-medium">
              10. Contact Information
            </h2>
            <p className="opacity-80">
              For any questions or concerns regarding these terms, please
              contact us at{" "}
              <span className="text-blue-600">hello@atman.io.</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
