import React, { useState, useEffect } from "react";
import Banner from "../Components/Banner";
import UnveilNewWorlds from "../Components/UnveilNewWorlds";
import AccordionItem from "../Components/AccordionItems";
import Steps from "../Components/Steps";
import CTA from "../Components/CTA";
import Footer from "../Footer";
import Credits from "../Components/Credits";
import ComingSoon from "./ComingSoon";

const HomePage = () => {
  const [showActualSite, setShowActualSite] = useState<boolean>(false);
  const [keySequence, setKeySequence] = useState<string>("");

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      setKeySequence((prevSequence) => prevSequence + e.key);

      if (keySequence.slice(-6) === "heyman") {
        setShowActualSite(true);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [keySequence]);

  return (
    <div className="bg-dark font-Manrope">
      {showActualSite ? (
        <div>
          <Banner />
          <UnveilNewWorlds />
          <AccordionItem />
          <Steps />

          <CTA />
          <Footer />
          <Credits />
        </div>
      ) : (
        <ComingSoon />
      )}
    </div>
  );
};

export default HomePage;
