import { Accordion, User } from "../types/UserTypes";

const accordian: Accordion[] = [
  {
    id: "01",
    desc: "  Think of it as having a cup of ramen with Naruto or sharing a laugh with Goku, just a laid-back space to connect and learn.",
    title: "Sessions",
    imageUrl: "/Assets/card2.jpg",
    color: "bg-orange-200",
  },
  {
    id: "02",
    desc: "  Think of it as having a cup of ramen with Naruto or sharing a laugh with Goku, just a laid-back space to connect and learn.",
    title: "Meet with Industry Experts",
    imageUrl: "/Assets/card3.png",
    color: "bg-pink-200",
  },
  {
    id: "03",
    desc: "Think of it as having a cup of ramen with Naruto or sharing a laugh with Goku, just a laid-back space to connect and learn.",
    title: "Priority Dm's",
    imageUrl: "/Assets/card1.jpg",
    color: "bg-violet-200",
  },
  {
    id: "04",
    desc: " Think of it as having a cup of ramen with Naruto or sharing a laugh with Goku, just a laid-back space to connect and learn.",
    title: "Video Answers",
    imageUrl: "/Assets/card2.jpg",
    color: "bg-green-200",
  },
  {
    id: "05",
    desc: " Think of it as having a cup of ramen with Naruto or sharing a laugh with Goku, just a laid-back space to connect and learn.",
    title: "Generate Atman Link",
    imageUrl: "/Assets/card3.png",
    color: "bg-blue-200",
  },
];
export default accordian;
